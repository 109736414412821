
import Vue from "vue";
import SearchBlock from "@/components/SearchMentor/SearchBlock.vue";
import MentorcastData from "@/components/Mentorcast/MentorcastData.vue";

export default Vue.extend({
  name: "SearchMentorPage",
  components: {
    SearchBlock,
    MentorcastData
  },
  methods: {
    loadMore() {
      console.log("Load More button was clicked!");
    }
  }
});
