
import Vue from "vue";

export default Vue.extend({
  name: "SearchBlock",
  props: {
    isSearchField: {
      type: Boolean
    }
  },
  data() {
    return {
      selectedLanguage: {
        label: (this as any).$t("search.selectedLanguageEnglish"),
        value: "en"
      },
      selectedLocation: {
        label: (this as any).$t("search.selectedLocationUK"),
        value: "London, UK"
      },
      // Select options
      languageOptions: [
        {
          label: (this as any).$t("search.languageOptionsEnglish"),
          value: "en"
        },
        {
          label: (this as any).$t("search.languageOptionsSpanish"),
          value: "es"
        },
        {
          label: (this as any).$t("search.languageOptionsFrench"),
          value: "fr"
        },
        {
          label: (this as any).$t("search.languageOptionsGerman"),
          value: "de"
        }
      ],
      locationOptions: [
        {
          label: (this as any).$t("search.locationOptionsUK"),
          value: "London, UK"
        },
        {
          label: (this as any).$t("search.locationOptionsUSA"),
          value: "Washington, USA"
        },
        {
          label: (this as any).$t("search.locationOptionsSpain"),
          value: "Madrid, Spain"
        }
      ],
      // Select arrow icon
      OpenIndicator: {
        render: (createElement: any) =>
          createElement("inline-svg", {
            attrs: {
              src: require(`@/assets/icons/arrow.svg`),
              width: 20,
              height: 20
            }
          })
      },
      // Fast search buttons
      buttons: [
        {
          name: (this as any).$t("search.buttonsNutrition")
        },
        {
          name: (this as any).$t("search.buttonsNutritionWorkLifeBalance")
        },
        {
          name: (this as any).$t("search.buttonsFinances")
        },
        {
          name: (this as any).$t("search.buttonsMentalHealth")
        },
        {
          name: (this as any).$t("search.buttonsProductivity")
        },
        {
          name: (this as any).$t("search.buttonsFitness")
        },
        {
          name: (this as any).$t("search.buttonsSports")
        },
        {
          name: (this as any).$t("search.buttonsSeeMore")
        }
      ]
    };
  },
  methods: {
    categorySearch(i: number) {
      console.log("Search " + this.buttons[i].name);
    }
  }
});
